/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "黑龙江神顶峰黑蜂产品有限公司成立于 2005 年，是黑龙江省农业产业化重点龙头企业。公司地址在虎林市东方红镇工业园区，占地 22300 平方米，注册资本 500 万元，固定资产 3147 万元，年生产加工能力 5000 吨，现有职工 38 人。公司品牌“神顶峰”为完达山脉主峰，海拔 831 米，是中国迎来第一缕阳光的地方之一；公司采取“公司+合作社+蜂农”的经营模式，在东北黑蜂国家级自然保护区内拥有百户示范蜂场，是中国养蜂学会“成熟蜜基地”。获绿色食品、欧盟有机及农产品地理标志认证。领办的森蜜蜂业农民合作社为国家级农民合作社示范社，有成员 131 户，蜂群 2 万多群，带动周边蜂户 600 多户。"), "\n", React.createElement(_components.p, null, "二十年来，神顶峰人不忘初心，只为一罐好蜜，带动林区下岗职工养黑蜂，回归自然酿好蜜，保护东北黑蜂和椴树资源，打造雪蜜品牌，研发的黑蜂雪蜜先后荣获第 41 届、第 44 届国际养蜂大会金、银、铜奖“，5 次获中国国际农交会金奖。中国养蜂学会授予公司“中国蜂业国家影响力金奖”；授予品牌创始人战立新“全国蜂业突出贡献奖”，鸡西市授予“拔尖人才”称号；“雪蜜”品牌获黑龙江省十大农业企业品牌称号。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
